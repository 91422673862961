import { gql } from "graphql-request";

export const InboxIssueFieldsFragment = gql`
  fragment InboxIssueFields on InboxIssue {
    id
    productId
    sortSections {
      id
      name
    }
    updatedAt
    publishedAt
  }
`;
