import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import {
  Add,
  CheckCircleOutline,
  ExpandMore as ExpandMoreIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import { gql } from "graphql-request";
import { QueryStatus } from "@tanstack/react-query";
import { DraggableProvided } from "react-beautiful-dnd";
import { useSocialMedia } from "@tbml/hooks/useSocialMedia";
import { Spacer } from "@tbml/components/Spacer";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { Issue } from "@tbml/hooks/useIssues";
import {
  SocialMediaPlatform,
  socialMediaPlatforms,
} from "@tbml/api-interface/socialMedia";
import { SocialMediaIcon } from "@tbml/components/SocialMediaPlatform/SocialMediaIcon";
import { Text, TextSmall } from "@tbml/components/Typography";
import { SocialMediaDialog } from "../Issues/SocialMediaDialog";
import { SectionAccordionHeader, SocialMediaSelector } from "./styles";
import { SocialMediaPreviewDialog } from "./SocialMediaPreviewDialog";

type SocialSectionProps = {
  issue: Issue;
  customerId: string;
  draggedItem: DraggableProvided;
  forceSocialMediaPreviewDialogOpen?: SocialMediaPlatform | undefined;
  forceSocialMediaDialogOpen?: boolean;
};

type SocialInfoIconProps = {
  status: QueryStatus;
  hasInboxSocialMediaId: boolean;
  haveArticles: boolean;
};
type SocialInfoTextProps = {
  status: QueryStatus;
  haveArticles: boolean;
  hasConnectedProductId: boolean;
};

const socialMediaCustomerFragmentName = "SocialMediaCustomerFields";
const socialMediaCustomerFragment = gql`
  fragment SocialMediaCustomerFields on Customer {
    inboxSocialMediaId
  }
`;

function SocialMediaInfoIcon({
  status,
  hasInboxSocialMediaId,
  haveArticles,
}: SocialInfoIconProps) {
  if (status === "pending" && hasInboxSocialMediaId)
    return (
      <CircularProgress size={24} aria-label="Social Media data is loading" />
    );
  return status === "success" && hasInboxSocialMediaId && haveArticles ? (
    <CheckCircleOutline
      color="primary"
      aria-label="No warnings in Social Media section"
    />
  ) : (
    <WarningAmberIcon
      color="warning"
      aria-label="Warnings in Social Media section"
    />
  );
}
function SocialMediaInfoText({
  status,
  haveArticles,
  hasConnectedProductId,
}: SocialInfoTextProps) {
  if (!hasConnectedProductId) {
    return (
      <Grid item>
        <Text>
          To add articles, please connect the social media feed to an Inbox
          product on the configuration page!
        </Text>
      </Grid>
    );
  }
  if (status === "success" && !haveArticles) {
    return (
      <Grid item>
        <Text>No Social Media Articles found</Text>
      </Grid>
    );
  }
  if (status === "pending") {
    return (
      <Grid item>
        <Text>Social Media Articles are loading - Please wait...</Text>
      </Grid>
    );
  }
  return (
    <Grid item>
      <Text>Social Media Articles loading failed</Text>
    </Grid>
  );
}

export function SocialSection({
  issue,
  customerId,
  forceSocialMediaPreviewDialogOpen = undefined,
  forceSocialMediaDialogOpen = false,
  draggedItem,
}: SocialSectionProps): JSX.Element {
  const [socialMediaDialogOpen, setSocialMediaDialogOpen] =
    React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [platformForPreviewDialog, setPlatformForPreviewDialog] =
    React.useState<SocialMediaPlatform | undefined>();
  const { query: socialMediaQuery } = useSocialMedia();
  const { query } = useCustomers();
  const { data: customers } = query({
    filter: { ids: [customerId] },
    fragmentName: socialMediaCustomerFragmentName,
    fragment: socialMediaCustomerFragment,
  });
  const customer = customers && customers[0];

  const { data: socialMediaArticles, status } = socialMediaQuery({
    inboxSocialMediaId: customer?.inboxSocialMediaId
      ? customer.inboxSocialMediaId
      : "",
    issueIds: [issue.id],
    enabled: !!customer?.inboxSocialMediaId,
  });

  React.useEffect(() => {
    if (
      forceSocialMediaPreviewDialogOpen !== undefined &&
      forceSocialMediaPreviewDialogOpen !== platformForPreviewDialog
    )
      setPlatformForPreviewDialog(forceSocialMediaPreviewDialogOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceSocialMediaPreviewDialogOpen]);

  React.useEffect(() => {
    if (
      forceSocialMediaDialogOpen &&
      forceSocialMediaDialogOpen !== socialMediaDialogOpen
    )
      setSocialMediaDialogOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceSocialMediaPreviewDialogOpen]);

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={(event, newExpanded) => {
          setExpanded(newExpanded);
        }}
        aria-label="Social Media Accordion"
        ref={draggedItem.innerRef}
        {...draggedItem.draggableProps}
        {...draggedItem.dragHandleProps}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SectionAccordionHeader as="div">
            <SocialMediaInfoIcon
              status={status}
              haveArticles={!!(socialMediaArticles ?? []).length}
              hasInboxSocialMediaId={!!customer?.inboxSocialMediaId}
            />
            <Spacer size="horizontalXs" />
            Social Media
          </SectionAccordionHeader>
        </AccordionSummary>
        <AccordionDetails>
          {customer?.inboxSocialMediaId && (
            <SocialMediaDialog
              productId={customer.inboxSocialMediaId}
              issueId={issue.id}
              onClose={(reason: string) => {
                if (reason === "escapeKeyDown" || reason === "backdropClick")
                  return;
                setSocialMediaDialogOpen(false);
              }}
              open={socialMediaDialogOpen}
            />
          )}
          <div>
            <Button
              onClick={() => setSocialMediaDialogOpen(true)}
              startIcon={<Add />}
              variant="outlined"
              aria-label="Open social media dialog"
              disabled={!customer?.inboxSocialMediaId?.length}
              fullWidth
            >
              Add social media article
            </Button>
            <Spacer size="verticalM" />
            <Grid container spacing={3}>
              {socialMediaArticles?.length ? (
                Object.values(socialMediaPlatforms).map((platform) => (
                  <Grid key={platform.label} item>
                    <SocialMediaSelector
                      onClick={() => setPlatformForPreviewDialog(platform)}
                    >
                      <Badge
                        badgeContent={
                          socialMediaArticles.filter(({ mediaId }) =>
                            mediaId
                              ? platform.mediaIds.includes(mediaId)
                              : false
                          ).length
                        }
                        color="primary"
                        aria-label={`${platform.label} badge`}
                      >
                        <SocialMediaIcon platform={platform} />
                      </Badge>
                      <Spacer size="paddingXs" />
                      <TextSmall>{platform.label} Articles</TextSmall>
                    </SocialMediaSelector>
                  </Grid>
                ))
              ) : (
                <SocialMediaInfoText
                  status={status}
                  haveArticles={!!(socialMediaArticles ?? []).length}
                  hasConnectedProductId={!!customer?.inboxSocialMediaId}
                />
              )}
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      {socialMediaArticles && platformForPreviewDialog && (
        <SocialMediaPreviewDialog
          open={!!platformForPreviewDialog}
          onClose={() => setPlatformForPreviewDialog(undefined)}
          articles={socialMediaArticles}
          platform={platformForPreviewDialog}
        />
      )}
    </>
  );
}
