import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Issue } from "@tbml/hooks/useIssues";
import { SocialMediaPlatform } from "@tbml/api-interface/socialMedia";
import { ExecutiveBriefingSection } from "./ExecutiveBriefingSection";
import { AdditionalCoverageSection } from "./AdditionalCoverageSection";
import { AnalyticsSection } from "./AnalyticsSection";
import { SocialSection } from "./SocialSection";

export type Props = {
  customerId: string;
  forceSocialMediaDialogOpen?: boolean;
  forceSocialMediaPreviewDialogOpen?: SocialMediaPlatform;
  issue: Issue;
  draggedItem: DraggableProvided;

  onInboxProductChange: (inboxProductIssueId: string | null) => void;
  enableAnIContent?: boolean;
};

export const sectionComponentsMap: {
  [key: string]: React.FC<Props>;
} = {
  analytics: AnalyticsSection,
  coverage: AdditionalCoverageSection,
  executive: ExecutiveBriefingSection,
  social: SocialSection,
};
