import * as React from "react";
import { IssuePublicationStatus } from "@tbml/api-interface/graphql";
import { ClickableTooltip } from "@tbml/components/ClickableTooltip";
import { Text } from "@tbml/components/Typography";
import { Issue } from "@tbml/hooks/useIssues";
import { PublishedIcon, UnpublishedIcon, WhiteAvatar } from "./styles";

export function IssueAvatar({
  issue,
  isDefault,
}: {
  issue: Issue;
  isDefault: boolean;
}): JSX.Element | null {
  switch (issue.publicationStatus) {
    case IssuePublicationStatus.Published:
      return (
        <ClickableTooltip
          title={
            isDefault ? (
              <Text color="inherit">
                This issue is the currently default issue. It will be shown as
                the default issue when visiting the Briefings reader.
              </Text>
            ) : (
              <Text color="inherit">
                This issue is published and visible by customers.
              </Text>
            )
          }
        >
          <WhiteAvatar aria-label="Issue is published">
            <PublishedIcon />
          </WhiteAvatar>
        </ClickableTooltip>
      );
    case IssuePublicationStatus.WorkInProgress:
      return (
        <ClickableTooltip
          title={
            <Text color="inherit">
              This issue is still a work-in-progress and not viewable by
              customers.
            </Text>
          }
        >
          <WhiteAvatar aria-label="Issue is work in progress">
            <UnpublishedIcon />
          </WhiteAvatar>
        </ClickableTooltip>
      );
    default:
      return null;
  }
}
