import * as React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import getConfig from "next/config";
import { Article } from "@tbml/hooks/useArticles";
import { SocialMediaArticle } from "@tbml/components/SocialMediaPlatform";
import { SocialMediaPlatform } from "@tbml/api-interface/socialMedia";
import { Spacer } from "@tbml/components/Spacer";

type SocialMediaPreviewDialogProps = {
  open?: boolean;
  onClose?: () => void;
  articles: Article[];
  platform: SocialMediaPlatform;
};

export function SocialMediaPreviewDialog({
  articles,
  onClose = () => {},
  open = false,
  platform,
}: SocialMediaPreviewDialogProps): JSX.Element {
  const foundArticle = articles.filter((article) =>
    article.mediaId ? platform.mediaIds.includes(article.mediaId) : false
  );

  const inboxFrontendUrl =
    getConfig()?.publicRuntimeConfig?.inboxFrontendUrl ||
    "https://inbox-frontend-url";

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      aria-labelledby="add-social-media-dialog"
    >
      <DialogTitle typography="h3">{platform.label}</DialogTitle>
      <DialogContent>
        <Grid wrap="nowrap" container spacing={2}>
          {foundArticle.map(({ deeplink, productId, id, mediaId, oEmbed }) => (
            <Grid
              key={id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              item
            >
              <Button
                variant="text"
                size="small"
                target="_blank"
                endIcon={<OpenInNewIcon />}
                href={`${inboxFrontendUrl}/product/${productId}/records/${id}`}
              >
                View in Inbox
              </Button>
              {mediaId && deeplink && (
                <SocialMediaArticle
                  mediaId={mediaId}
                  url={deeplink}
                  embedCode={oEmbed?.html}
                />
              )}
            </Grid>
          ))}
          <Spacer size="paddingM" />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
