import * as React from "react";
import { useRouter } from "@tbml/shared-dependencies/router";
import { Text } from "@tbml/components/Typography";
import { Container } from "@tbml/components/PageLayout";
import { Issues } from "../../components/Issues";

function IssuesPage(): JSX.Element {
  const router = useRouter();
  const { customerId } = router.query;

  if (customerId === undefined || customerId instanceof Array) {
    return <Text>error</Text>;
  }

  return (
    <Container>
      <Issues customerId={customerId} />
    </Container>
  );
}

export default IssuesPage;
