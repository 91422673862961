import * as React from "react";
import { ClickAwayListener } from "@mui/material";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { Issue } from "@tbml/hooks/useIssues";
import { FullWidthTextField } from "./styles";

export function IssueTitlePicker({
  issue,
  onChange,
  onClose,
}: {
  issue: Issue;
  onChange: (newTitle: string) => void;
  onClose: () => void;
}): JSX.Element {
  const [titleFieldValue, setTitleFieldValue] = React.useState<string>(
    issue.title ?? ""
  );
  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose();
      }}
    >
      <FullWidthTextField
        variant="standard"
        label="Enter title for this issue"
        fullWidth
        autoFocus
        defaultValue={issue.title}
        placeholder={`Issue for ${format(parseISO(issue.refDate), "PPPP")}`}
        onChange={(e) => {
          setTitleFieldValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onChange(titleFieldValue);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            e.preventDefault();
            onClose();
          }
        }}
      />
    </ClickAwayListener>
  );
}
