import * as React from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {
  Autocomplete,
  createFilterOptions,
  Box,
  Divider,
  TextField,
  ClickAwayListener,
} from "@mui/material";
import {
  AddLink as AddLinkIcon,
  Inbox as InboxIcon,
} from "@mui/icons-material";
import { Issue } from "@tbml/hooks/useIssues";
import { InboxProductIssue } from "@tbml/api-interface/inboxProductIssues/types";
import { Spacer } from "@tbml/components/Spacer";
import { Text, TextSmall } from "@tbml/components/Typography";
import { VerticallyCentered } from "./styles";

type InboxIssueIdOption = {
  inputValue?: string;
  id: string;
  publishedAt?: string;
};
const filter = createFilterOptions<InboxIssueIdOption>();

export function InboxProductIssueIdPicker({
  onInboxProductChange,
  issue,
  setEditingInboxProductIssueId,
  inboxIssueData,
}: {
  issue: Issue;
  onInboxProductChange: (inboxProductIssueId: string | null) => void;
  setEditingInboxProductIssueId: React.Dispatch<React.SetStateAction<boolean>>;
  inboxIssueData: { inboxIssues: InboxProductIssue[] } | undefined;
}): JSX.Element {
  const [inboxProductIssueIdFieldValue, setInboxProductIssueIdFieldValue] =
    React.useState<InboxIssueIdOption | null>(
      issue.inboxProductIssueId
        ? {
            id: issue.inboxProductIssueId,
          }
        : null
    );

  React.useEffect(() => {
    if (issue.inboxProductIssueId === inboxProductIssueIdFieldValue) return;
    setInboxProductIssueIdFieldValue(
      issue?.inboxProductIssueId
        ? {
            id: issue.inboxProductIssueId,
          }
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue.inboxProductIssueId]);

  const [inboxIssueDropdownOpen, setInboxIssueDropdownOpen] =
    React.useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (inboxIssueDropdownOpen) return;

        setEditingInboxProductIssueId(false);
      }}
    >
      <Autocomplete<InboxIssueIdOption, false, false, true>
        defaultValue={{
          id: issue.inboxProductIssueId ?? "",
        }}
        value={inboxProductIssueIdFieldValue}
        selectOnFocus
        handleHomeEndKeys
        freeSolo
        onClose={() => {
          setInboxIssueDropdownOpen(false);
        }}
        onOpen={() => {
          setInboxIssueDropdownOpen(true);
        }}
        onBlur={(e) => {
          const value =
            (e.target as HTMLInputElement).value === ""
              ? null
              : (e.target as HTMLInputElement).value;

          if (inboxProductIssueIdFieldValue === value) return;

          setInboxProductIssueIdFieldValue(
            value === null ? null : { id: value }
          );
          onInboxProductChange(value);
          setEditingInboxProductIssueId(false);
        }}
        onChange={(event, newValue) => {
          if (newValue === null) {
            onInboxProductChange(newValue);
            return setInboxProductIssueIdFieldValue(newValue);
          }
          if (typeof newValue === "string") {
            onInboxProductChange(newValue);
            setEditingInboxProductIssueId(false);
            return setInboxProductIssueIdFieldValue({ id: newValue });
          }
          if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            onInboxProductChange(newValue.inputValue);
            setEditingInboxProductIssueId(false);
            return setInboxProductIssueIdFieldValue({
              id: newValue.inputValue,
            });
          }
          if (newValue && newValue.id) {
            // Create a new value from the user input
            onInboxProductChange(newValue.id);
            setEditingInboxProductIssueId(false);
            return setInboxProductIssueIdFieldValue({
              id: newValue.id,
            });
          }
          throw new Error(
            `received unexpected value for inbox product issue id: ${JSON.stringify(
              newValue
            )}`
          );
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.id);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              id: `Link with "${inputValue}"`,
            });
          }

          return filtered;
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={(inboxIssueData?.inboxIssues ?? []).map<InboxIssueIdOption>(
          (inboxIssue) => ({
            id: inboxIssue.id,
            publishedAt: inboxIssue.publishedAt,
          })
        )}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.id;
        }}
        renderOption={(optionProps, option) => {
          if (option.inputValue) {
            return (
              <React.Fragment key={optionProps.id}>
                <Box component="li" {...optionProps}>
                  <Box sx={{ width: "100%" }}>
                    <VerticallyCentered>
                      <AddLinkIcon color="inherit" />
                      <Spacer size="paddingXs" />
                      <Text>Link with {option.inputValue}</Text>
                    </VerticallyCentered>
                  </Box>
                </Box>
                <Spacer size="paddingXs" />
                <Divider />
                <Spacer size="paddingXs" />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={optionProps.id}>
              <Box
                component="li"
                {...optionProps}
                aria-label={`Link ${issue.id} with inbox product issue ${option.id}`}
              >
                <Box sx={{ width: "100%" }}>
                  {option.publishedAt && (
                    <VerticallyCentered>
                      <InboxIcon color="inherit" />
                      <Spacer size="paddingXs" />
                      <Text>{format(parseISO(option.publishedAt), "PP")}</Text>
                    </VerticallyCentered>
                  )}
                  <TextSmall>ID: {option.id}</TextSmall>
                </Box>
              </Box>
              <Spacer size="paddingXs" />
              <Divider />
              <Spacer size="paddingXs" />
            </React.Fragment>
          );
        }}
        renderInput={(params) => {
          const shownInput =
            (
              params.inputProps as typeof params.inputProps & {
                value?: string;
              }
            ).value &&
            inboxIssueData?.inboxIssues.find(
              (inboxIssue) =>
                inboxIssue.id ===
                (
                  params.inputProps as typeof params.inputProps & {
                    value?: string;
                  }
                ).value
            );
          return (
            <TextField
              {...params}
              variant="standard"
              {...(shownInput
                ? {
                    inputProps: {
                      ...params.inputProps,
                      value: format(parseISO(shownInput.publishedAt), "PP"),
                    },
                  }
                : {})}
              label="Linked Inbox Product Issue"
            />
          );
        }}
      />
    </ClickAwayListener>
  );
}
