import * as React from "react";
import { ClickAwayListener, Tooltip, TooltipProps } from "@mui/material";

export function ClickableTooltip({
  children,
  ...props
}: TooltipProps): JSX.Element {
  const [open, setOpen] = React.useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        {...props}
        open={open}
        onClick={() => setOpen((prev) => !prev)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
}
