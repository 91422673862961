import { gql, GraphQLClient } from "graphql-request";
import {
  QueryKey,
  QueryObserverResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { InboxIssueFieldsFragment } from "@tbml/api-interface/inboxProductIssues";
import { InboxIssueFilter } from "@tbml/api-interface/graphql";
import { InboxProductIssue } from "@tbml/api-interface/inboxProductIssues/types";
import { useApi } from "../useApi";

const inboxIssuesQueryFn = async (
  client: GraphQLClient,
  filter: InboxIssueFilter
): Promise<InboxProductIssue[]> => {
  const {
    getInboxIssues: { inboxIssues },
  } = await client.request<
    { getInboxIssues: { inboxIssues: InboxProductIssue[] } },
    { filter: InboxIssueFilter }
  >(
    gql`
      query GetInboxIssues($filter: InboxIssueFilter!) {
        getInboxIssues(filter: $filter) {
          inboxIssues {
            ...InboxIssueFields
          }
        }
      }
      ${InboxIssueFieldsFragment}
    `,
    { filter }
  );
  return inboxIssues;
};

export const useInboxIssues = ({
  filter,
  enabled = true,
}: {
  filter: InboxIssueFilter;
  enabled?: boolean;
}): QueryObserverResult<{ inboxIssues: InboxProductIssue[] }, Error> => {
  const { client, token } = useApi();
  const queryClient = useQueryClient();
  return useQuery<{ inboxIssues: InboxProductIssue[] }, Error>({
    queryKey: ["inboxIssues", filter],
    queryFn: async () => {
      const inboxIssues = await inboxIssuesQueryFn(client, filter);

      if (inboxIssues) {
        inboxIssues.forEach((inboxIssue) => {
          queryClient.setQueryData(["inboxIssue", inboxIssue.id], inboxIssue);
        });
      }
      return { inboxIssues };
    },
    enabled: !!token && enabled,
    placeholderData: () => {
      if (!filter) return undefined;

      const allCachedInboxIssues =
        queryClient.getQueriesData<InboxProductIssue>({
          predicate: ({ queryKey }) => queryKey[0] === "inboxIssue",
        });

      const isNotEmpty = (
        item: [QueryKey, InboxProductIssue | undefined]
      ): item is [QueryKey, InboxProductIssue] => !!item[1];

      const cachedInboxIssues = allCachedInboxIssues
        .filter(isNotEmpty)
        .filter(([, cachedInboxIssue]) => {
          if (filter.ids?.includes(cachedInboxIssue.id)) return true;
          return !!filter.productIds?.includes(cachedInboxIssue.productId);
        })
        .map(([, inboxIssue]) => inboxIssue);

      if (cachedInboxIssues.length === 0) {
        return undefined;
      }

      return {
        inboxIssues: cachedInboxIssues,
      };
    },
  });
};
