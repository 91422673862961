import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import formatISO from "date-fns/formatISO";
import { ClickAwayListener } from "@mui/material";
import parseISO from "date-fns/parseISO";
import { Issue } from "@tbml/hooks/useIssues";
import { FullWidthTextField } from "./styles";

const isValidDate = (d: unknown): d is Date =>
  d instanceof Date && !!d.getDate();

export function IssueRefDatePicker({
  issue,
  onClose,
  onChange,
}: {
  issue: Issue;
  onClose: () => void;
  onChange: (newDate: string) => void;
}): JSX.Element {
  const [refDateFieldValue, setRefDateFieldValue] = React.useState<string>(
    issue.refDate
  );
  const [datepickerOpen, setDatepickerOpen] = React.useState(false);

  const handleAccept = (date: Date | null) => {
    if (!date) return;
    setRefDateFieldValue(formatISO(date));
    onChange(formatISO(date));
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ClickAwayListener
        onClickAway={() => {
          if (datepickerOpen) return;
          onClose();
        }}
      >
        <DesktopDateTimePicker
          inputFormat="Pp"
          renderInput={(inputProps) => (
            <FullWidthTextField
              inputProps={{
                "aria-label": "date-time-picker-input",
              }}
              variant="standard"
              {...inputProps}
            />
          )}
          OpenPickerButtonProps={{
            "aria-label": "Click to open date picker",
          }}
          label="Enter date for this issue"
          onOpen={() => {
            setDatepickerOpen(true);
          }}
          onClose={() => {
            setDatepickerOpen(false);
          }}
          value={parseISO(refDateFieldValue)}
          onAccept={handleAccept}
          onChange={(d: Date | null, rawInput) => {
            if (!isValidDate(d) || !rawInput) return;
            if (isValidDate(rawInput)) return; // ui input is handled by onAccept

            const tzShiftedDate = formatISO(
              new Date(d.getTime() - d.getTimezoneOffset() * 60_000)
            );

            setRefDateFieldValue(tzShiftedDate);
            onChange(tzShiftedDate);
          }}
        />
      </ClickAwayListener>
    </LocalizationProvider>
  );
}
