import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  WarningAmber as WarningAmberIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircleOutline,
} from "@mui/icons-material";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { gql } from "graphql-request";
import { DraggableProvided } from "react-beautiful-dnd";
import { Text, TextSmall } from "@tbml/components/Typography";
import { useArticles } from "@tbml/hooks/useArticles";
import { Spacer } from "@tbml/components/Spacer";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { useInboxIssues } from "@tbml/hooks/useInboxIssues";
import { Issue } from "@tbml/hooks/useIssues";
import { ArticleStatus } from "@tbml/api-interface/graphql";
import { InboxProductIssueIdValidator } from "./InboxProductIssueIdValidator";
import { DottedListItemText, SectionAccordionHeader } from "./styles";
import { InboxProductIssueIdPicker } from "./InboxProductIssueIdPicker";

const additionalCoverageArticleFragmentName = "AdditionalCoverageArticleFields";
const additionalCoverageArticleFragment = gql`
  fragment AdditionalCoverageArticleFields on Article {
    id
  }
`;
const additionalCoverageCustomerFragmentName =
  "AdditionalCoverageCustomerFields";
const additionalCoverageCustomerFragment = gql`
  fragment AdditionalCoverageCustomerFields on Customer {
    inboxAddcovId
  }
`;

export function AdditionalCoverageSection({
  issue,
  customerId,
  onInboxProductChange,
  draggedItem,
}: {
  issue: Issue;
  customerId: string;
  onInboxProductChange: (inboxProductIssueId: string | null) => void;
  draggedItem: DraggableProvided;
}): JSX.Element {
  const [editingInboxProductIssueId, setEditingInboxProductIssueId] =
    React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const { query } = useCustomers();
  const { data: customers } = query({
    filter: { ids: [customerId] },
    fragmentName: additionalCoverageCustomerFragmentName,
    fragment: additionalCoverageCustomerFragment,
  });
  const customer = customers && customers[0];

  const { data: inboxIssueData } = useInboxIssues({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    filter: { productIds: customer ? [customer.inboxAddcovId!] : [] },
    enabled: !!customer?.inboxAddcovId,
  });
  const selectedInboxProductIssue = React.useMemo(
    () =>
      inboxIssueData?.inboxIssues.find(
        (inboxIssue) => inboxIssue.id === issue.inboxProductIssueId
      ),
    [issue.inboxProductIssueId, inboxIssueData]
  );

  const shouldFetchArticles = !!issue.inboxProductIssueId;
  const {
    data: articles,
    status,
    error,
  } = useArticles({
    fragmentName: additionalCoverageArticleFragmentName,
    fragment: additionalCoverageArticleFragment,
  }).query({
    filter: {
      ...(issue.inboxProductIssueId
        ? { inboxProductIssueIds: [issue.inboxProductIssueId] }
        : {}),
      status: [
        ArticleStatus.Confirmed,
        ArticleStatus.ConfirmedAutomatically,
        ArticleStatus.Proposed,
      ],
    },
    enabled: shouldFetchArticles,
  });

  const hasNoAdditionalCoverageWarnings = React.useMemo(() => {
    if (shouldFetchArticles && status === "pending") return true;
    return (
      status === "success" &&
      selectedInboxProductIssue &&
      (articles ?? []).length > 6
    );
  }, [articles, selectedInboxProductIssue, shouldFetchArticles, status]);

  if (shouldFetchArticles && status === "pending") {
    return <Text>Loading Additional Coverage...</Text>;
  }

  if (status === "error") {
    return (
      <Accordion
        expanded={expanded}
        onChange={(event, newExpanded) => {
          setExpanded(newExpanded);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SectionAccordionHeader as="div">
            Additional Coverage
            <Spacer size="horizontalXs" />
            <WarningAmberIcon color="error" />
          </SectionAccordionHeader>
        </AccordionSummary>
        <AccordionDetails>
          <Alert severity="error">
            <AlertTitle>Error getting articles:</AlertTitle>
            <TextSmall>{error?.message}</TextSmall>
          </Alert>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, newExpanded) => {
        setExpanded(newExpanded);
      }}
      ref={draggedItem.innerRef}
      {...draggedItem.draggableProps}
      {...draggedItem.dragHandleProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <SectionAccordionHeader as="div">
          {hasNoAdditionalCoverageWarnings ? (
            <CheckCircleOutline
              color="primary"
              aria-label="No warnings in Additional Coverage section"
            />
          ) : (
            <WarningAmberIcon
              color="warning"
              aria-label="Warnings in Additional Coverage section"
            />
          )}
          <Spacer size="horizontalXs" />
          Additional Coverage
        </SectionAccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        {editingInboxProductIssueId ? (
          <InboxProductIssueIdPicker
            onInboxProductChange={onInboxProductChange}
            issue={issue}
            setEditingInboxProductIssueId={setEditingInboxProductIssueId}
            inboxIssueData={inboxIssueData}
          />
        ) : (
          <Box>
            <Text color="textSecondary">Linked Inbox Product Issue:</Text>
            <Spacer size="paddingXs" />
            <List dense>
              {selectedInboxProductIssue ? (
                <ListItem>
                  <ListItemIcon>
                    <InboxProductIssueIdValidator issue={issue} />
                  </ListItemIcon>
                  <DottedListItemText
                    aria-label="Click to link an inbox product issue"
                    title={selectedInboxProductIssue.id}
                    onClick={() => setEditingInboxProductIssueId(true)}
                    sx={{
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                    }}
                  >
                    {format(
                      parseISO(selectedInboxProductIssue.publishedAt),
                      "PP"
                    )}
                  </DottedListItemText>
                </ListItem>
              ) : (
                <ListItem>
                  <ListItemIcon>
                    <InboxProductIssueIdValidator issue={issue} />
                  </ListItemIcon>
                  <DottedListItemText
                    title={issue.inboxProductIssueId ?? ""}
                    onClick={() => setEditingInboxProductIssueId(true)}
                    aria-label="Click to link an inbox product issue"
                    color={
                      !issue.inboxProductIssueId ||
                      issue.inboxProductIssueId === ""
                        ? "textSecondary"
                        : undefined
                    }
                  >
                    {!issue.inboxProductIssueId ||
                    issue.inboxProductIssueId === ""
                      ? "Click to link an inbox product issue"
                      : issue.inboxProductIssueId}
                  </DottedListItemText>
                </ListItem>
              )}
            </List>
          </Box>
        )}
        <Spacer />
        <Text color="textSecondary">Checklist:</Text>
        <List dense>
          <ListItem>
            {(articles ?? []).length > 6 ? (
              <ListItemIcon aria-label="Additional Coverage contains a good amount of articles">
                <CheckCircleOutline color="primary" />
              </ListItemIcon>
            ) : (
              <ListItemIcon aria-label="Additional Coverage does not contain a good amount of articles">
                <WarningAmberIcon color="warning" />
              </ListItemIcon>
            )}
            <ListItemText>More than 6 articles</ListItemText>
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
