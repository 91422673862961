import * as React from "react";
import { ButtonProps } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "@tbml/shared-dependencies/notistack";
import { useCurrentCustomerId } from "@tbml/hooks/useCurrentCustomerId";
import { Issue, useIssues } from "@tbml/hooks/useIssues";
import { IssueInput } from "@tbml/api-interface/graphql";
import { useStatus } from "../AppBar";
import { CreateNewIssueButton } from "./styles";
import { issuesPageIssueFragment, issuesPageIssueFragmentName } from "./index";

export type Props = ButtonProps & {
  onIssueCreated?: (newIssue: Issue) => void;
};

export const createNewIssueInput = (
  customerId: string,
  issueId = uuid()
): IssueInput => ({
  id: issueId,
  title: "",
  customerId,
  backlogOrder: [],
  refDate: new Date(),
  analyticsSummary: null,
  analyticsSectionTitle: null,
});

export function IssueCreationButton({
  onClick = () => {},
  onIssueCreated = () => {},
  ...props
}: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { setStatus } = useStatus({ persistAfterUnmount: true });
  const [currentCustomerId] = useCurrentCustomerId();

  const {
    mutator: { mutate },
  } = useIssues({
    fragmentName: issuesPageIssueFragmentName,
    fragment: issuesPageIssueFragment,
  });

  const createIssue = React.useCallback(() => {
    if (!currentCustomerId) {
      throw new Error(
        "Could not get customer when trying to create a new issue."
      );
    }

    const issueInput = createNewIssueInput(currentCustomerId);

    setStatus("saving");
    mutate(issueInput, {
      onSuccess: (issue) => {
        setStatus("saved");
        onIssueCreated(issue);
        setTimeout(() => {
          setStatus("idle");
        }, 5000);
      },
      onError: (e) => {
        enqueueSnackbar(`Could not create issue: ${e?.message}`, {
          variant: "error",
        });
        setStatus("idle");
      },
    });
  }, [currentCustomerId, setStatus, mutate, onIssueCreated, enqueueSnackbar]);

  return (
    <CreateNewIssueButton
      {...props}
      variant="contained"
      onClick={(...args) => {
        createIssue();
        onClick(...args);
      }}
    >
      Create new issue
    </CreateNewIssueButton>
  );
}
