import { Button, DialogActions, styled, TextField } from "@mui/material";
import tokens from "@tbml/tokens";

export const Headline = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const VerticallyCentered = styled("div")`
  display: flex;
  align-items: center;
`;

export const CreateNewIssueButton = styled(Button)`
  height: 35px;
`;

export const RightColumn = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FullWidthTextField = styled(TextField)`
  width: 100%;
`;

export const SocialMediaPlaceholder = styled("div")`
  display: grid;
  place-items: center;
  width: 100%;
  height: 550px;
  border: 1px solid ${tokens.color.divider.value};
  border-radius: 12px;
`;

export const SupportedPlatformsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const SupportedPlatforms = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${tokens.spacing.verticalXs.value};
`;

export const PreviewWrapper = styled("div")`
  display: flex;
  justify-content: center;
  min-height: 550px;
`;

export const SocialMediaDialogActions = styled(DialogActions)`
  padding: 0;
`;

export const PlatformCheckContainer = styled("div")``;
