import React, { useMemo } from "react";
import dynamic from "next/dynamic";
import { Link, Typography } from "@mui/material";
import {
  getPlatformDataByMediaId,
  socialMediaInboxMedium,
  SocialMediaPlatform,
  socialMediaPlatforms,
} from "@tbml/api-interface/socialMedia";
import { TwitterArticleProps } from "./Twitter";
import { FacebookArticleProps } from "./Facebook";
import {
  ArticleError,
  PortraitFormatArticleSkeleton,
  YouTubeArticleSkeleton,
} from "./styles";
import { YoutubeArticleProps } from "./YouTube";
import { InstagramArticleProps } from "./Instagram";
import { TIKTOK_DEFAULT_DIMENSIONS } from "./TikTok";
import { LinkedInArticleProps } from "./LinkedIn";

export type SocialMediaArticleProps = {
  url?: string;
  embedCode?: string;
  mediaId: number;
  loading?: boolean;
};

type SocialMediaArticleSkeletonProps = {
  platform: SocialMediaPlatform;
};

export { SOCIAL_MEDIA_MIN_WIDTH } from "./styles";

export const TwitterArticleDynamic = dynamic<TwitterArticleProps>(
  () => import("./Twitter").then(({ TwitterArticle }) => TwitterArticle),
  {
    loading: () => <PortraitFormatArticleSkeleton />,
  }
);

export const FacebookArticleDynamic = dynamic<FacebookArticleProps>(
  () => import("./Facebook").then(({ FacebookArticle }) => FacebookArticle),
  {
    loading: () => <PortraitFormatArticleSkeleton />,
  }
);

export const LinkedInArticleDynamic = dynamic<LinkedInArticleProps>(
  () => import("./LinkedIn").then(({ LinkedInArticle }) => LinkedInArticle),
  {
    loading: () => <PortraitFormatArticleSkeleton />,
  }
);

export const YouTubePostDynamic = dynamic<YoutubeArticleProps>(
  () => import("./YouTube").then(({ YouTubeArticle }) => YouTubeArticle),
  {
    loading: () => <YouTubeArticleSkeleton />,
  }
);
export const InstagramPostDynamic = dynamic<InstagramArticleProps>(
  () => import("./Instagram").then(({ InstagramArticle }) => InstagramArticle),
  {
    loading: () => <PortraitFormatArticleSkeleton />,
  }
);

export const TikTokPostDynamic = dynamic<InstagramArticleProps>(
  () => import("./TikTok").then(({ TikTokArticle }) => TikTokArticle),
  {
    loading: () => (
      <PortraitFormatArticleSkeleton {...TIKTOK_DEFAULT_DIMENSIONS} />
    ),
  }
);

export function SocialMediaArticleSkeleton({
  platform,
}: SocialMediaArticleSkeletonProps): JSX.Element {
  switch (platform) {
    case socialMediaPlatforms.youtube:
      return <YouTubeArticleSkeleton />;
    case socialMediaPlatforms.tiktok:
      return <PortraitFormatArticleSkeleton {...TIKTOK_DEFAULT_DIMENSIONS} />;
    default:
      return <PortraitFormatArticleSkeleton />;
  }
}

export function NoSourceError(): JSX.Element {
  return (
    <ArticleError>
      <Typography component="div">No source found</Typography>
    </ArticleError>
  );
}

export function IFrameRenderError({
  embedCode = undefined,
}: {
  embedCode?: string | null;
}): JSX.Element {
  return (
    <ArticleError>
      <div>
        <Typography component="div">Embed could not be rendered</Typography>
        <pre>{embedCode}</pre>
      </div>
    </ArticleError>
  );
}

export function NoEmbedRendererFoundError({
  deeplink = undefined,
}: {
  deeplink?: string | null;
}): JSX.Element {
  return (
    <ArticleError>
      <div>
        <Typography component="div">Embed could not be rendered</Typography>
        {!!deeplink && (
          <Link href={deeplink}>Click here to open the article</Link>
        )}
      </div>
    </ArticleError>
  );
}

export function SocialMediaArticle({
  mediaId,
  url = undefined,
  embedCode = undefined,
  loading = false,
}: SocialMediaArticleProps): JSX.Element {
  const platformData = useMemo(
    () => getPlatformDataByMediaId(mediaId),
    [mediaId]
  );

  switch (platformData) {
    case socialMediaInboxMedium.twitter:
      if (!url) return <NoEmbedRendererFoundError deeplink={url} />;
      return <TwitterArticleDynamic deeplink={url} loading={loading} />;
    case socialMediaInboxMedium.facebookPost:
      if (!url) return <NoEmbedRendererFoundError deeplink={url} />;
      return (
        <FacebookArticleDynamic
          deeplink={url}
          platformData={platformData}
          loading={loading}
        />
      );
    case socialMediaInboxMedium.facebookVideo:
      if (!url) return <NoEmbedRendererFoundError deeplink={url} />;
      return (
        <FacebookArticleDynamic
          deeplink={url}
          platformData={platformData}
          loading={loading}
        />
      );
    case socialMediaInboxMedium.youtube:
      if (!url) return <NoEmbedRendererFoundError deeplink={url} />;
      return <YouTubePostDynamic deeplink={url} loading={loading} />;
    case socialMediaInboxMedium.instagram:
      if (!url) return <NoEmbedRendererFoundError deeplink={url} />;
      return <InstagramPostDynamic deeplink={url} loading={loading} />;
    case socialMediaInboxMedium.tiktok:
      if (!url) return <NoEmbedRendererFoundError deeplink={url} />;
      return <TikTokPostDynamic deeplink={url} loading={loading} />;
    case socialMediaInboxMedium.linkedIn:
      if (embedCode)
        return (
          <LinkedInArticleDynamic embedCode={embedCode} loading={loading} />
        );
      if (url) return <LinkedInArticleDynamic url={url} loading={loading} />;
      return <IFrameRenderError embedCode={embedCode} />;
    default:
      return <NoEmbedRendererFoundError deeplink={url} />;
  }
}
