import * as React from "react";
import { useState } from "react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { DraggableProvided } from "react-beautiful-dnd";
import { Link } from "@tbml/components/Link";
import { Issue } from "@tbml/hooks/useIssues";
import { SectionAccordionHeader } from "./styles";

export function AnalyticsSection({
  customerId,
  issue,
  draggedItem,
  enableAnIContent = false,
}: {
  customerId: string;
  issue: Issue;
  draggedItem: DraggableProvided;
  enableAnIContent?: boolean;
}): JSX.Element | null {
  const [expanded, setExpanded] = useState(false);

  if (!customerId && !enableAnIContent) {
    return null;
  }
  return (
    <Accordion
      expanded={expanded}
      onChange={(_, newExpanded) => {
        setExpanded(newExpanded);
      }}
      ref={draggedItem.innerRef}
      {...draggedItem.draggableProps}
      {...draggedItem.dragHandleProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} component="div">
        <SectionAccordionHeader as="div">
          Analytics & Insights
        </SectionAccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Link href={`/${customerId}/issue/${issue.id}/analytics`}>
          <Button variant="contained">Go to widget setup</Button>
        </Link>
      </AccordionDetails>
    </Accordion>
  );
}
