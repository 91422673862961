import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import getConfig from "next/config";
import { OpenInNew, ShortText } from "@mui/icons-material";
import { gql } from "graphql-request";
import { useQueryClient } from "@tanstack/react-query";
import { Spacer } from "@tbml/components/Spacer";
import { useArticles } from "@tbml/hooks/useArticles";
import {
  RecordData,
  socialMediaInboxMedium,
  socialMediaPlatforms,
} from "@tbml/api-interface/socialMedia";
import { useSnackbar } from "@tbml/shared-dependencies/notistack";
import { SocialMediaArticle } from "@tbml/components/SocialMediaPlatform";
import { SocialMediaIcon } from "@tbml/components/SocialMediaPlatform/SocialMediaIcon";
import { ActionContainer } from "@tbml/components/SocialMediaPlatform/styles";
import { useSetStatus } from "../AppBar";
import {
  FullWidthTextField,
  PreviewWrapper,
  RightColumn,
  SocialMediaDialogActions,
  SocialMediaPlaceholder,
  SupportedPlatforms,
  SupportedPlatformsContainer,
} from "./styles";

type SocialMediaDialogProps = {
  open: boolean;
  productId: string;
  issueId: string;
  onClose: (reason: string) => void;
};

export type FormData = {
  deeplinkInput: string;
  concreteFacebookMediaId: number;
};

export const socialMediaArticleMutationFragmentName =
  "SocialMediaArticleMutationFields";
export const socialMediaArticleMutationFragment = gql`
  fragment SocialMediaArticleMutationFields on Article {
    id
  }
`;

export function SocialMediaDialog({
  open,
  productId,
  issueId,
  onClose,
}: SocialMediaDialogProps): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      deeplinkInput: "",
      concreteFacebookMediaId: -1,
    },
  });
  const {
    mutator: { mutate },
  } = useArticles({
    fragmentName: socialMediaArticleMutationFragmentName,
    fragment: socialMediaArticleMutationFragment,
  });
  const { setStatus, removeStatus } = useSetStatus();
  const [inputValue, setInputValue] = React.useState<string | undefined>();
  const [recordData, setRecordData] = React.useState<RecordData>();
  const watchInput = watch("deeplinkInput", undefined);
  const watchConcreteFacebookMediaId = watch(
    "concreteFacebookMediaId",
    undefined
  );
  const queryClient = useQueryClient();

  const inboxFrontendUrl =
    getConfig()?.publicRuntimeConfig?.inboxFrontendUrl ||
    "https://inbox-frontend-url";

  // to force rerender after changing deeplink input
  React.useEffect(() => {
    if (watchInput !== inputValue) {
      setInputValue(watchInput);
    }
  }, [watch, inputValue, watchInput, watchConcreteFacebookMediaId]);

  const handleClose = React.useCallback(
    (reason: string) => {
      reset({ deeplinkInput: "", concreteFacebookMediaId: undefined });
      onClose(reason);
    },
    [onClose, reset]
  );

  const onSubmit = React.useCallback(
    (data: FormData) => {
      if (!recordData) {
        enqueueSnackbar(
          `Cannot detect platform from URL ${data.deeplinkInput}`,
          {
            variant: "error",
          }
        );
        return;
      }
      setStatus("saving");
      const snackbarId = enqueueSnackbar(
        `Sending ${recordData.mediaName} article to inbox`,
        {
          variant: "info",
          autoHideDuration: null,
          action: <CircularProgress color="inherit" />,
        }
      );
      mutate(
        {
          source: data.deeplinkInput,
          mediaId: recordData.mediaId,
          inboxProductId: productId,
          issueId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["articles"] });
            enqueueSnackbar(
              `${recordData.mediaName} article successfully created`,
              {
                variant: "success",
              }
            );
            closeSnackbar(snackbarId);
            setStatus("saved");
            setTimeout(removeStatus, 5000);
            setInputValue(undefined);
          },
          onError: () => {
            enqueueSnackbar(
              `${recordData.mediaName} article can not be sent to inbox`,
              {
                variant: "error",
              }
            );
            closeSnackbar(snackbarId);
            removeStatus();
            setInputValue(undefined);
          },
        }
      );
      handleClose("submitClose");
    },
    [
      issueId,
      recordData,
      setStatus,
      enqueueSnackbar,
      mutate,
      productId,
      handleClose,
      queryClient,
      closeSnackbar,
      removeStatus,
    ]
  );

  function isSocialMediaEmbed(input: string) {
    return (
      Object.values(socialMediaInboxMedium).some(
        ({ record, urlPattern, embedCodePattern }) => {
          if (urlPattern.test(input) || embedCodePattern?.test(input)) {
            setRecordData(record);
            return true;
          }
          return false;
        }
      ) || "Wrong url for this platform"
    );
  }

  const deeplinkNotIdentifyFacebookPlatform =
    inputValue &&
    socialMediaInboxMedium.facebookPost.urlPattern.test(inputValue) &&
    socialMediaInboxMedium.facebookVideo.urlPattern.test(inputValue);

  const SocialMediaComponent = React.useCallback(
    ({ input, mediaId }: { input: string; mediaId: number }) => {
      if (/^http/.test(input))
        return <SocialMediaArticle mediaId={mediaId} url={input} />;
      if (/^<iframe/.test(input)) {
        return <SocialMediaArticle mediaId={mediaId} embedCode={input} />;
      }
      return (
        <SocialMediaPlaceholder>
          <SupportedPlatformsContainer>
            <ShortText>Unable to render the input</ShortText>
          </SupportedPlatformsContainer>
        </SocialMediaPlaceholder>
      );
    },
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby="add-social-media-dialog"
    >
      <DialogTitle typography="h3">Add social media article</DialogTitle>
      <DialogContent>
        <Spacer size="verticalXs" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={10}
            rowSpacing={2}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Controller
                name="deeplinkInput"
                control={control}
                rules={{
                  required: "Social media url / embed code is required",
                  validate: {
                    isSocialMediaEmbed,
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <FullWidthTextField
                    error={!!errors.deeplinkInput}
                    helperText={errors.deeplinkInput?.message}
                    label="Social media url / embed code"
                    inputProps={{
                      "aria-label":
                        "Social media article url / embed code input",
                    }}
                    variant="standard"
                    placeholder="Enter the social media url / embed code for a supported platform"
                    autoFocus
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <PreviewWrapper>
                {!errors.deeplinkInput && inputValue && recordData ? (
                  <SocialMediaComponent
                    input={inputValue}
                    mediaId={recordData.mediaId}
                  />
                ) : (
                  <SocialMediaPlaceholder>
                    <SupportedPlatformsContainer>
                      Supported platforms
                      <SupportedPlatforms>
                        {Object.values(socialMediaPlatforms).map((platform) => (
                          <SocialMediaIcon
                            key={platform.label}
                            platform={platform}
                          />
                        ))}
                      </SupportedPlatforms>
                    </SupportedPlatformsContainer>
                  </SocialMediaPlaceholder>
                )}
              </PreviewWrapper>
            </Grid>
            <Grid item xs={6}>
              <RightColumn>
                <Box>
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    <strong>
                      An article will be created for the Executive Briefing{" "}
                      <strong>
                        added to the{" "}
                        <Link
                          underline="always"
                          href={`${inboxFrontendUrl}/product/${productId}`}
                        >
                          Inbox product
                          <OpenInNew fontSize="inherit" />
                        </Link>
                      </strong>{" "}
                      of this customer
                    </strong>
                    . It might take several minutes for the social media article
                    to appear in the social media section.
                  </Alert>
                  <Spacer size="verticalM" />
                  <Alert severity="warning">
                    <AlertTitle>
                      <Box display="flex" alignItems="start">
                        <SocialMediaIcon
                          key={socialMediaPlatforms.linkedIn.label}
                          platform={socialMediaPlatforms.linkedIn}
                          size="small"
                        />
                        LinkedIn
                      </Box>
                    </AlertTitle>
                    <ul>
                      <li>For LinkedIn URL or the embed code can be used.</li>
                      <li>
                        Due to restrictions from LinkedIn side, no images are
                        displayed for LinkedIn posts in Firefox.
                      </li>
                    </ul>
                  </Alert>
                  <Spacer size="verticalM" />
                  {deeplinkNotIdentifyFacebookPlatform && (
                    <Alert
                      severity={
                        watchConcreteFacebookMediaId ? "success" : "warning"
                      }
                    >
                      <AlertTitle>Does this post contain a Video?</AlertTitle>
                      <ActionContainer>
                        <Controller
                          name="concreteFacebookMediaId"
                          control={control}
                          rules={{
                            validate: {
                              required: (value) =>
                                value > 0 || "Selection is required",
                            },
                          }}
                          defaultValue={undefined}
                          render={({ field }) => (
                            <FormControl
                              error={!!errors.concreteFacebookMediaId}
                              variant="standard"
                            >
                              <RadioGroup
                                row
                                {...field}
                                name="facebook-video-check"
                              >
                                <FormControlLabel
                                  aria-label="Embed does not contain Facebook Video"
                                  value={
                                    socialMediaInboxMedium.facebookPost.record
                                      .mediaId
                                  }
                                  control={<Radio />}
                                  label="No"
                                />
                                <FormControlLabel
                                  aria-label="Embed contains Facebook Video"
                                  value={
                                    socialMediaInboxMedium.facebookVideo.record
                                      .mediaId
                                  }
                                  control={<Radio />}
                                  label="Yes"
                                />
                              </RadioGroup>
                              <FormHelperText>
                                {errors.concreteFacebookMediaId?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </ActionContainer>
                    </Alert>
                  )}
                </Box>
                <SocialMediaDialogActions>
                  <Button
                    onClick={() => handleClose("cancelBtnClose")}
                    variant="outlined"
                  >
                    cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    aria-label="Add social media article button"
                  >
                    add social media article
                  </Button>
                </SocialMediaDialogActions>
              </RightColumn>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
