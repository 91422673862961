import * as React from "react";
import { Inbox as InboxIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Issue } from "@tbml/hooks/useIssues";

export function InboxProductIssueIdValidator({
  issue,
}: {
  issue: Issue;
}): JSX.Element | null {
  const additionalCoverageError = React.useMemo(
    () =>
      issue.errors?.find((error) =>
        error?.startsWith(
          "Could not fetch data for Additional Coverage section"
        )
      ),
    [issue]
  );

  if (!issue.inboxProductIssueId) return null;

  if (!additionalCoverageError)
    return <InboxIcon color="success" aria-label="Inbox Issue was found" />;

  return (
    <Tooltip title={additionalCoverageError}>
      <InboxIcon color="error" aria-label="Error getting Inbox Issue" />
    </Tooltip>
  );
}
