import { gql, GraphQLClient } from "graphql-request";
import {
  QueryObserverResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Notification,
  NotificationFilter,
  NotificationInput,
  NotificationOutput,
} from "@tbml/api-interface/graphql";
import { useApi } from "../useApi";
import { UseNotificationsFieldFragment } from "./fields";

export type UseNotificationResult = {
  query: (args: {
    filter?: NotificationFilter;
    enabled?: boolean;
  }) => QueryObserverResult<Notification[], Error>;
  mutator: UseMutationResult<
    { notification: Notification; code: string | null },
    Error,
    NotificationInput
  >;
};

const notificationQueryFn = async (
  client: GraphQLClient,
  filter: NotificationFilter
): Promise<Notification[]> => {
  const {
    getNotifications: { notifications },
  } = await client.request<{
    getNotifications: { notifications: Notification[] };
  }>(
    gql`
      query GetNotifications($filter: NotificationFilter!) {
        getNotifications(filter: $filter) {
          notifications {
            ...UseNotificationsField
          }
        }
      }
      ${UseNotificationsFieldFragment}
    `,
    { filter }
  );
  return notifications;
};

export const useNotifications = (): UseNotificationResult => {
  const queryClient = useQueryClient();
  const { client, token } = useApi();

  const useNotificationQuery = ({
    filter = {},
    enabled,
  }: {
    filter?: NotificationFilter;
    enabled?: boolean;
  }) =>
    useQuery<Notification[], Error>({
      queryKey: ["notifications"],
      queryFn: async () => {
        const notifications = await notificationQueryFn(client, filter ?? {});
        notifications.forEach((notification) => {
          queryClient.setQueryData(
            ["notification", notification.id],
            notification
          );
        });
        return notifications;
      },
      enabled: enabled && !!token,
    });

  const mutator = useMutation<
    { code: string | null; notification: Notification },
    Error,
    NotificationInput
  >({
    mutationFn: async (input) => {
      const {
        createNotification: {
          notifications: [notification],
          code,
        },
      } = await client.request<{
        createNotification: NotificationOutput;
      }>(
        gql`
          mutation CreateNotification($input: NotificationInput!) {
            createNotification(input: $input) {
              code
              notifications {
                ...UseNotificationsField
              }
            }
          }
          ${UseNotificationsFieldFragment}
        `,
        { input }
      );
      return { notification, code };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["issues"] });
    },
  });

  return {
    query: useNotificationQuery,
    mutator,
  };
};
