import * as React from "react";
import {
  ExpandMore as ExpandMoreIcon,
  WarningAmber as WarningAmberIcon,
  Topic as TopicIcon,
  CheckCircleOutline,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Delta from "quill-delta";
import { DraggableProvided } from "react-beautiful-dnd";
import { Image } from "@tbml/components/Image";
import { ExecutiveBriefingTopic, Issue } from "@tbml/hooks/useIssues";
import { Spacer } from "@tbml/components/Spacer";
import { Link } from "@tbml/components/Link";
import { StoryInsideTopic } from "@tbml/api-interface/graphql";
import {
  CollapsableContent,
  ImagePlaceholder,
  SectionAccordionHeader,
  STORY_PREVIEW_HEIGHT,
  STORY_PREVIEW_WIDTH,
  TopicTitle,
} from "./styles";

const allStoriesContainImage = (stories: StoryInsideTopic[]) =>
  stories.every((story) => !!story.image);

const allStoriesContainEditorial = (stories: StoryInsideTopic[]) =>
  stories.every((story) => {
    const length = new Delta(story.editorial.ops).length();
    return length > 100 && length < 1100;
  });

const topicCorrectStoryCount = (topic: ExecutiveBriefingTopic) =>
  (topic.stories ?? []).length > 1 && (topic.stories ?? []).length <= 6;

const topicStoryCountMessage = (topic: ExecutiveBriefingTopic) => {
  if ((topic.stories ?? []).length === 0) {
    return (
      <ListItem>
        <ListItemIcon aria-label={`${topic.title} contains no stories`}>
          <WarningAmberIcon color="warning" />
        </ListItemIcon>
        <ListItemText>Contains no stories</ListItemText>
      </ListItem>
    );
  }

  if (topicCorrectStoryCount(topic)) {
    return (
      <ListItem>
        <ListItemIcon
          aria-label={`${topic.title} contains the right amount of stories`}
        >
          <CheckCircleOutline color="primary" />
        </ListItemIcon>
        <ListItemText>Between 2 and 6 stories</ListItemText>
      </ListItem>
    );
  }

  return (
    <ListItem>
      <ListItemIcon
        aria-label={`${topic.title} does not contain the right amount of stories`}
      >
        <WarningAmberIcon color="warning" />
      </ListItemIcon>
      <ListItemText>Between 2 and 6 stories</ListItemText>
    </ListItem>
  );
};

function ExecutiveBriefingTopicWarnings({
  topic,
}: {
  topic: ExecutiveBriefingTopic;
}): JSX.Element {
  return (
    <List dense>
      {/* Amount of stories */}
      {topicStoryCountMessage(topic)}
      {/* All stories have images */}
      {(topic.stories ?? []).length > 0 && (
        <ListItem>
          {allStoriesContainImage(topic.stories ?? []) ? (
            <ListItemIcon
              aria-label={`All stories in ${topic.title} contain an image`}
            >
              <CheckCircleOutline color="primary" />
            </ListItemIcon>
          ) : (
            <ListItemIcon
              aria-label={`Not all stories in ${topic.title} contain an image`}
            >
              <WarningAmberIcon color="warning" />
            </ListItemIcon>
          )}
          <ListItemText>All stories contain an image</ListItemText>
        </ListItem>
      )}
      {/* All stories have editorial texts */}
      {(topic.stories ?? []).length > 0 && (
        <ListItem>
          {allStoriesContainEditorial(topic.stories ?? []) ? (
            <ListItemIcon
              aria-label={`All stories in ${topic.title} have a good editorial length`}
            >
              <CheckCircleOutline color="primary" />
            </ListItemIcon>
          ) : (
            <ListItemIcon
              aria-label={`Not all stories in ${topic.title} have a good editorial length`}
            >
              <WarningAmberIcon color="warning" />
            </ListItemIcon>
          )}
          <ListItemText>
            All stories have an editorial of 100 - 1100 characters
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
}

export function ExecutiveBriefingSection({
  customerId,
  issue,
  draggedItem,
}: {
  customerId: string;
  issue: Issue;
  draggedItem: DraggableProvided;
}): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);
  const hasNoExecutiveBriefingWarnings = React.useMemo(
    () =>
      (issue.executiveBriefingTopics ?? []).every(
        (topic) =>
          topicCorrectStoryCount(topic) &&
          allStoriesContainImage(topic.stories ?? []) &&
          allStoriesContainEditorial(topic.stories ?? [])
      ),
    [issue]
  );

  const totalStories = React.useMemo(
    () =>
      (issue.executiveBriefingTopics ?? []).reduce(
        (acc, topic) => acc + (topic.stories ?? []).length,
        0
      ),
    [issue.executiveBriefingTopics]
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, newExpanded) => {
        setExpanded(newExpanded);
      }}
      ref={draggedItem.innerRef}
      {...draggedItem.draggableProps}
      {...draggedItem.dragHandleProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} component="div">
        <SectionAccordionHeader as="div">
          {hasNoExecutiveBriefingWarnings ? (
            <CheckCircleOutline color="primary" />
          ) : (
            <WarningAmberIcon color="warning" />
          )}
          <Spacer size="horizontalXs" />
          Executive Briefing
        </SectionAccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Link href={`/${customerId}/issue/${issue.id}`}>
          <Button variant="contained">Go to Storyboard</Button>
        </Link>
        <Spacer />
        {totalStories > 0 ? (
          (issue.executiveBriefingTopics ?? []).map((topic) => (
            <CollapsableContent key={topic.id}>
              <TopicTitle color="textSecondary">
                <TopicIcon color="inherit" fontSize="small" />
                <Spacer size="paddingXs" />
                {topic.title}
              </TopicTitle>
              <ExecutiveBriefingTopicWarnings topic={topic} />
              <Grid container spacing={1}>
                {(topic.stories ?? []).map((story) =>
                  story.image ? (
                    <Grid item xs="auto" key={story.id}>
                      <Link
                        href={`/${customerId}/issue/${issue.id}/story/${story.id}`}
                      >
                        <Image
                          src={story.image?.originPath}
                          width={STORY_PREVIEW_WIDTH}
                          height={STORY_PREVIEW_HEIGHT}
                          alt=""
                        />
                      </Link>
                    </Grid>
                  ) : (
                    <Grid item xs="auto" key={story.id}>
                      <Link
                        href={`/${customerId}/issue/${issue.id}/story/${story.id}`}
                      >
                        <ImagePlaceholder />
                      </Link>
                    </Grid>
                  )
                )}
              </Grid>
              <Spacer />
            </CollapsableContent>
          ))
        ) : (
          <ListItem>
            <ListItemIcon aria-label="This issue contains no stories yet">
              <WarningAmberIcon color="warning" />
            </ListItemIcon>
            <ListItemText>This issue contains no stories yet</ListItemText>
          </ListItem>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
