import * as React from "react";
import {
  ArrowDropDown as ArrowDropDownIcon,
  MoreVert as MoreVertIcon,
  Preview as PreviewIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import {
  Box,
  CardContent,
  CircularProgress,
  ClickAwayListener,
  DialogContentText,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";
import getConfig from "next/config";
import { captureException } from "@sentry/browser";
import { LoadingButton } from "@mui/lab";
import { closestTo, differenceInSeconds, isFuture, isPast } from "date-fns";
import { gql } from "graphql-request";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { TextXsmall } from "@tbml/components/Typography";
import { Issue, useIssues } from "@tbml/hooks/useIssues";
import { Spacer } from "@tbml/components/Spacer";
import { useSnackbar } from "@tbml/shared-dependencies/notistack";
import { useCustomers } from "@tbml/hooks/useCustomers";
import {
  IssuePublicationStatus,
  Notification,
} from "@tbml/api-interface/graphql";
import { Link } from "@tbml/components/Link";
import { useNotifications } from "@tbml/hooks/useNotifications";
import { NOTIFICATION_CANCEL_THRESHOLD } from "@tbml/api-interface/notifications";
import { SocialMediaPlatform } from "@tbml/api-interface/socialMedia";
import tokens from "@tbml/tokens";
import { DEFAULT_SECTIONS_ORDER } from "@tbml/api-interface/issue";
import { ConfirmDialog } from "../ConfirmDialog";
import { useStatus } from "../AppBar";
import {
  issuesPageIssueFragment,
  issuesPageIssueFragmentName,
} from "../Issues";
import {
  ActionButton,
  DefaultChip,
  IssueCard,
  IssueOverviewHeader,
  PrimaryActionButtonGroup,
  FullWidthCardActions,
  TitleText,
} from "./styles";
import { IssueAvatar } from "./IssueAvatar";
import { IssueTitlePicker } from "./IssueTitlePicker";
import { IssueRefDatePicker } from "./IssueRefDatePicker";
import { sectionComponentsMap } from "./SectionOrder";

export type Props = {
  issue: Issue;
  customerId: string;
  isDefault: boolean;
  forceDeleteModalOpen?: boolean;
  forceSocialMediaDialogOpen?: boolean;
  forceSocialMediaPreviewDialogOpen?: SocialMediaPlatform;
};

const config = getConfig();
const { viewerUrl } = config?.publicRuntimeConfig || {
  viewerUrl: "https://viewer-url",
};

const issueOverviewItemCustomerFragmentName = "IssueOverviewItemCustomerFields";
const issueOverviewItemCustomerFragment = gql`
  fragment IssueOverviewItemCustomerFields on Customer {
    defaultIssueId
    contactListIds
    image {
      originPath
    }
    brandColor
    enableAnIContent
    audioIssueEnabled
    name
    templateId
  }
`;

function NotificationOption({
  issue,
  isDefault = false,
  lockedScheduledNotification = undefined,
  ...props
}: React.HTMLProps<HTMLDivElement> & {
  issue: Issue;
  isDefault: boolean;
  lockedScheduledNotification?: Notification;
}): JSX.Element {
  if (!(issue.notifications ?? []).length) return <>Send notification</>;
  const [futureNotificationDate] = (issue.notifications ?? [])
    .filter(
      (notification) =>
        notification.scheduledAt && isFuture(parseISO(notification.scheduledAt))
    )
    .map(({ scheduledAt }) => parseISO(scheduledAt));

  const pastNotificationDate = closestTo(
    new Date(),
    (issue.notifications ?? [])
      .filter(({ scheduledAt }) => scheduledAt && isPast(parseISO(scheduledAt)))
      .map(({ scheduledAt }) => parseISO(scheduledAt))
  );

  if (lockedScheduledNotification) {
    return (
      <div {...props}>
        Scheduled notification
        <TextXsmall paragraph color={isDefault ? "textSecondary" : undefined}>
          Scheduled for{" "}
          {format(parseISO(lockedScheduledNotification.scheduledAt), "PP, p")}
        </TextXsmall>
      </div>
    );
  }
  const isValidDate =
    futureNotificationDate ||
    pastNotificationDate ||
    issue.notifications?.[0].scheduledAt;

  const isFailedNotification = issue.notifications?.[0]?.sent === false;

  let textColor;
  if (isFailedNotification) {
    textColor = tokens.color.errorMain.value;
  } else if (isDefault) {
    textColor = "textSecondary";
  } else {
    textColor = undefined;
  }

  return (
    <div {...props}>
      {futureNotificationDate
        ? "Reschedule notification"
        : "Resend notification"}
      <TextXsmall paragraph color={textColor}>
        {isFailedNotification ? (
          "Failed to send notification"
        ) : (
          <>
            {futureNotificationDate ? "Scheduled for " : "Last sent "}
            {isValidDate
              ? format(
                  futureNotificationDate ??
                    pastNotificationDate ??
                    parseISO(issue.notifications?.[0].scheduledAt),
                  "PP, p"
                )
              : "Error formatting the date and time of the publication notification"}
          </>
        )}
      </TextXsmall>
    </div>
  );
}

export function IssueOverviewItem({
  issue,
  isDefault,
  customerId,
  forceDeleteModalOpen = false,
  forceSocialMediaDialogOpen = false,
  forceSocialMediaPreviewDialogOpen = undefined,
}: Props): JSX.Element {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { setStatus } = useStatus({ persistAfterUnmount: true });
  const [publishOpen, setPublishOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [moreOpen, setMoreOpen] = React.useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const publishAnchorRef = React.useRef<HTMLDivElement>(null);
  const moreAnchorRef = React.useRef<HTMLButtonElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const {
    updater: { mutate: updateIssues },
    deleter: { mutate: deleteIssues },
    scheduleAudioGeneration: { mutate: scheduleAudioGenerationMutation },
  } = useIssues({
    fragmentName: issuesPageIssueFragmentName,
    fragment: issuesPageIssueFragment,
  });
  const {
    updater: { mutate: updateCustomer, isPending: savingCustomer },
    query: customerQuery,
  } = useCustomers({
    fragmentName: issueOverviewItemCustomerFragmentName,
    fragment: issueOverviewItemCustomerFragment,
  });
  const { mutator: notificationMutator } = useNotifications();
  const [editingRefDate, setEditingRefDate] = React.useState(false);
  const [editingTitle, setEditingTitle] = React.useState(false);
  const { data: customers, status: customerStatus } = customerQuery({
    filter: { ids: [customerId] },
    enabled: !!customerId,
  });

  const [customer] = customers ?? [];

  const initializeSectionOrder = React.useCallback(() => {
    const currentOrder =
      issue.sectionOrder && issue.sectionOrder.length
        ? issue.sectionOrder
        : DEFAULT_SECTIONS_ORDER;
    if (customer && customer.enableAnIContent && currentOrder) {
      return currentOrder.includes("analytics")
        ? currentOrder
        : ["analytics", ...currentOrder];
    }
    return (
      currentOrder && currentOrder.filter((section) => section !== "analytics")
    );
  }, [issue.sectionOrder, customer]);

  const [localSectionOrder, setLocalSectionOrder] = React.useState<string[]>(
    initializeSectionOrder
  );

  const prevSectionOrder = React.useRef(localSectionOrder);

  React.useEffect(() => {
    if (
      forceDeleteModalOpen !== undefined &&
      forceDeleteModalOpen !== deleteConfirmOpen
    )
      setDeleteConfirmOpen(forceDeleteModalOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceDeleteModalOpen]);

  const lockedScheduledNotification = React.useMemo(
    () =>
      (issue.notifications ?? [])
        .filter(({ scheduledAt }) => scheduledAt)
        .find(
          (notification) =>
            differenceInSeconds(
              parseISO(notification.scheduledAt),
              new Date()
            ) < NOTIFICATION_CANCEL_THRESHOLD &&
            isFuture(parseISO(notification.scheduledAt))
        ),
    [issue.notifications]
  );

  const isSendNotificationDisabled = customer
    ? (customer.contactListIds ?? []).length === 0
    : true;

  const mutationOptions = React.useMemo(
    () => ({
      onSuccess: () => {
        setStatus("saved");
        setTimeout(() => {
          setStatus("idle");
        }, 5_000);
      },
      onError: (e: Error) => {
        setStatus("idle");
        enqueueSnackbar(`An error occurred: ${e}`, {
          variant: "error",
        });
      },
    }),
    [enqueueSnackbar, setStatus]
  );

  const updateInboxProduct = React.useCallback(
    (inboxProductIssueId: string | null) => {
      setStatus("saving");
      updateIssues(
        {
          filter: { ids: [issue.id] },
          set: { inboxProductIssueId },
        },
        mutationOptions
      );
    },
    [issue.id, mutationOptions, setStatus, updateIssues]
  );

  const storyCount = React.useMemo(() => {
    if (!issue) return 0;
    return (issue.executiveBriefingTopics ?? []).reduce(
      (acc, topic) => acc + (topic.stories ?? []).length,
      0
    );
  }, [issue]);

  const createNotification = React.useCallback(() => {
    if (!customerId) {
      throw new Error("Could not get customer id for issue");
    }

    setStatus("saving");

    const notificationPayload = {
      issueId: issue.id,
      customerId,
      fallbackTheme: theme.palette.primary.main,
      viewerUrl,
    };
    const handleError = (error: Error) => {
      setStatus("idle");
      captureException(error);
      enqueueSnackbar("E-Mail Notification could not be sent", {
        variant: "error",
      });
    };
    notificationMutator.mutate(notificationPayload, {
      onSuccess: (result) => {
        if (result.code === "empty") {
          enqueueSnackbar(
            "Your contact list is empty. No notification was sent!",
            {
              variant: "warning",
            }
          );
          return;
        }

        if (!result.notification.sent) {
          handleError(new Error("Notification not sent"));
          return;
        }

        setStatus("saved");
        enqueueSnackbar("E-Mail Notification scheduled", {
          variant: "success",
        });
      },
      onError: handleError,
    });
  }, [
    theme.palette.primary.main,
    enqueueSnackbar,
    customerId,
    issue.id,
    notificationMutator,
    setStatus,
  ]);

  const options = React.useMemo<
    {
      label: string;
      action: () => void;
      disabled?: boolean;
      children?: React.ReactNode;
    }[]
  >(() => {
    if (!issue) return [];

    const sendNotificationOption = {
      label: "Send notification",
      children: (
        <NotificationOption
          issue={issue}
          isDefault={isDefault}
          lockedScheduledNotification={lockedScheduledNotification}
        />
      ),
      action: () => setConfirmOpen(true),
      disabled: isSendNotificationDisabled || !!lockedScheduledNotification,
    };

    if (
      isDefault &&
      issue.publicationStatus === IssuePublicationStatus.Published
    )
      return [sendNotificationOption];

    const setActiveIssue = () => {
      setStatus("saving");
      updateCustomer(
        {
          set: {
            defaultIssueId: issue.id,
            defaultIssue: {
              publicationStatus: IssuePublicationStatus.Published,
            },
          },
          filter: { ids: [customerId] },
        },
        mutationOptions
      );
      if (customer && customer.audioIssueEnabled) {
        scheduleAudioGenerationMutation({ id: issue.id });
        enqueueSnackbar("Scheduled audio generation for the issue", {
          variant: "info",
        });
      }
    };

    if (issue.publicationStatus === IssuePublicationStatus.Published)
      return [
        {
          label: "Set as default",
          action: setActiveIssue,
        },
        {
          label: "Unpublish",
          action: () => {
            updateIssues(
              {
                set: {
                  publicationStatus: IssuePublicationStatus.WorkInProgress,
                },
                filter: { ids: [issue.id] },
              },
              mutationOptions
            );
          },
        },
        sendNotificationOption,
      ];

    return [
      {
        label: "Publish & Set as default",
        action: setActiveIssue,
      },
      {
        label: "Publish",
        action: () => {
          updateIssues(
            {
              set: {
                publicationStatus: IssuePublicationStatus.Published,
              },
              filter: { ids: [issue.id] },
            },
            mutationOptions
          );
          if (customer && customer.audioIssueEnabled) {
            scheduleAudioGenerationMutation({ id: issue.id });
            enqueueSnackbar("Scheduled audio generation for the issue", {
              variant: "info",
            });
          }
        },
      },
    ];
  }, [
    setConfirmOpen,
    customer,
    customerId,
    isDefault,
    isSendNotificationDisabled,
    issue,
    mutationOptions,
    setStatus,
    lockedScheduledNotification,
    updateCustomer,
    updateIssues,
    enqueueSnackbar,
    scheduleAudioGenerationMutation,
  ]);

  const primaryActionLabel = React.useMemo(() => {
    if (savingCustomer) return "Loading";
    if (notificationMutator.isPending) return "Loading";
    if (customerStatus === "pending") return "Loading";
    return options[selectedIndex].children ?? options[selectedIndex].label;
  }, [
    customerStatus,
    notificationMutator.isPending,
    options,
    savingCustomer,
    selectedIndex,
  ]);

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setPublishOpen(false);
  };

  const isInEditMode = editingRefDate || editingTitle;

  React.useEffect(() => {
    const defaultOrder = ["executive", "coverage", "social"];
    const currentOrder =
      issue.sectionOrder && issue.sectionOrder.length
        ? issue.sectionOrder
        : defaultOrder;
    let newOrder;
    if (customer?.enableAnIContent && currentOrder) {
      if (currentOrder.includes("analytics")) {
        newOrder = currentOrder;
      } else {
        newOrder = ["analytics", ...currentOrder];
      }
    } else {
      newOrder = currentOrder.filter((section) => section !== "analytics");
    }

    const orderChanged =
      JSON.stringify(prevSectionOrder.current) !== JSON.stringify(newOrder);

    if (orderChanged) {
      prevSectionOrder.current = newOrder;
      setLocalSectionOrder(newOrder);

      if (newOrder !== currentOrder) {
        updateIssues(
          {
            filter: { ids: [issue.id] },
            set: { sectionOrder: newOrder },
          },
          mutationOptions
        );
      }
    }
  }, [issue.sectionOrder, customer, updateIssues, issue.id, mutationOptions]);

  const initialSections = React.useMemo(
    () =>
      localSectionOrder.map((sectionId) => {
        const SectionComponent = sectionComponentsMap[sectionId];
        const props = {
          customerId,
          issue,
          onInboxProductChange: updateInboxProduct,

          ...(sectionId === "social" && {
            forceSocialMediaDialogOpen,
            forceSocialMediaPreviewDialogOpen,
          }),
          ...(sectionId === "analytics" && {
            enableAnIContent: customer?.enableAnIContent ?? false,
          }),
        };
        return {
          id: sectionId,
          component: SectionComponent,
          props,
        };
      }),
    [
      localSectionOrder,
      customerId,
      issue,
      updateInboxProduct,
      forceSocialMediaDialogOpen,
      forceSocialMediaPreviewDialogOpen,
      customer?.enableAnIContent,
    ]
  );

  const [arrangedSections, setArrangedSections] =
    React.useState(initialSections);

  React.useEffect(() => {
    setArrangedSections(initialSections);
  }, [initialSections]);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newSections = Array.from(arrangedSections ?? []);
    const [movedSection] = newSections.splice(result.source.index, 1);
    newSections.splice(result.destination.index, 0, movedSection);

    setArrangedSections(newSections);

    const updatedSectionOrder = newSections.map((section) => section.id);

    setLocalSectionOrder(updatedSectionOrder);

    updateIssues(
      {
        filter: { ids: [issue.id] },
        set: { sectionOrder: updatedSectionOrder },
      },
      mutationOptions
    );
  };

  return (
    <IssueCard aria-label={`Issue ID: ${issue.id}`}>
      <IssueOverviewHeader
        issue={issue}
        isDefault={isDefault}
        avatar={<IssueAvatar issue={issue} isDefault={isDefault} />}
        action={
          <Box>
            {isDefault && !isInEditMode && (
              <DefaultChip color="success" label="Default" size="small" />
            )}
            <Spacer size="paddingS" inline />
            <Link
              title={`Show ${issue.title} in Briefings Reader`}
              href={`${viewerUrl}/${issue.id}?customerId=${customerId}`}
              target="_blank"
            >
              <IconButton
                aria-label={`Show ${issue.title} in Briefings Reader`}
              >
                <PreviewIcon />
              </IconButton>
            </Link>
            <IconButton
              aria-label={`Show more actions for issue ${issue.title}`}
              ref={moreAnchorRef}
              onClick={() => {
                setMoreOpen(true);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              open={moreOpen}
              onClose={() => {
                setMoreOpen(false);
              }}
              anchorEl={moreAnchorRef.current}
            >
              <MenuItem
                onClick={() => {
                  setDeleteConfirmOpen(true);
                  setMoreOpen(false);
                }}
                title={`Delete ${issue.title}`}
                aria-label={`Delete issue ${issue.id}`}
              >
                Delete
              </MenuItem>
            </Menu>
            <ConfirmDialog
              open={deleteConfirmOpen}
              onClose={() => {
                setDeleteConfirmOpen(false);
              }}
              onConfirmAction={() => {
                deleteIssues({ ids: [issue.id] }, mutationOptions);
                setDeleteConfirmOpen(false);
              }}
              onDeclineAction={() => {
                setDeleteConfirmOpen(false);
              }}
              dialogOptions={{
                confirmText: "Delete",
                declineText: "Cancel",
                title: `Delete ${issue.title !== "" ? issue.title : "issue"}?`,
                prompt: (
                  <>
                    <DialogContentText>
                      Are you sure you want to delete this issue?
                    </DialogContentText>
                    {storyCount > 0 && (
                      <DialogContentText>
                        {storyCount} {storyCount === 1 ? "story" : "stories"}{" "}
                        will be deleted as well.
                      </DialogContentText>
                    )}
                  </>
                ),
                confirmButton: {
                  title: `Confirm deleting ${issue.title}`,
                  ariaLabel: `Confirm deleting issue ${issue.id}`,
                },
              }}
            />
          </Box>
        }
        title={
          editingRefDate ? (
            <IssueRefDatePicker
              issue={issue}
              onClose={() => {
                setEditingRefDate(false);
              }}
              onChange={(newDate) => {
                updateIssues(
                  {
                    set: {
                      refDate: newDate,
                    },
                    filter: { ids: [issue.id] },
                  },
                  mutationOptions
                );
              }}
            />
          ) : (
            <TitleText
              aria-label="Click to edit date for this issue"
              onClick={() => {
                setEditingRefDate(true);
              }}
            >
              {format(parseISO(issue.refDate), "Pp")}
            </TitleText>
          )
        }
        subheader={
          editingTitle ? (
            <IssueTitlePicker
              aria-label="Enter title for this issue"
              issue={issue}
              onClose={() => {
                setEditingTitle(false);
              }}
              onChange={(newTitle) => {
                setEditingTitle(false);
                updateIssues(
                  {
                    set: {
                      title: newTitle,
                    },
                    filter: { ids: [issue.id] },
                  },
                  mutationOptions
                );
              }}
            />
          ) : (
            <TitleText
              aria-label="Click to edit title for this issue"
              onClick={() => {
                setEditingTitle(true);
              }}
              color={issue.title === "" ? "textSecondary" : undefined}
            >
              {issue.title === "" ? "Untitled" : issue.title}
            </TitleText>
          )
        }
      />
      <CardContent>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sections">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {arrangedSections?.map(
                  ({ id, props, component: Component }, index) => (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(draggedItem) => (
                        <Component {...props} draggedItem={draggedItem} />
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CardContent>

      <FullWidthCardActions>
        <PrimaryActionButtonGroup ref={publishAnchorRef}>
          <ActionButton
            variant={
              isDefault && (issue.notifications ?? []).length > 0
                ? "outlined"
                : "contained"
            }
            onClick={options[selectedIndex].action}
            disabled={
              options[selectedIndex].disabled ||
              customerStatus === "pending" ||
              notificationMutator.isPending ||
              savingCustomer
            }
            startIcon={
              notificationMutator.isPending || savingCustomer ? (
                <CircularProgress size="1em" color="inherit" />
              ) : null
            }
          >
            {primaryActionLabel}
          </ActionButton>
          {!isDefault && (
            <>
              <LoadingButton
                size="small"
                aria-expanded={publishOpen ? "true" : undefined}
                aria-label={
                  customerStatus === "pending"
                    ? "loading"
                    : "select publishing action"
                }
                loading={customerStatus === "pending"}
                aria-haspopup="menu"
                variant="contained"
                onClick={() => setPublishOpen((prevOpen) => !prevOpen)}
                sx={{ maxWidth: "1rem" }}
              >
                {customerStatus === "success" && <ArrowDropDownIcon />}
              </LoadingButton>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                placement="bottom-end"
                open={publishOpen}
                anchorEl={publishAnchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(event: Event) => {
                          if (
                            publishAnchorRef.current &&
                            publishAnchorRef.current.contains(
                              event.target as HTMLElement
                            )
                          ) {
                            return;
                          }

                          setPublishOpen(false);
                        }}
                      >
                        <MenuList autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option.label}
                              disabled={option.disabled}
                              selected={selectedIndex === index}
                              onClick={(event) => {
                                if (option.disabled) return;
                                handleMenuItemClick(event, index);
                              }}
                              aria-label={`Select ${option.label} as action`}
                            >
                              {option.children ?? option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </PrimaryActionButtonGroup>
        <ActionButton href={`/${customerId}/issue/${issue.id}`} variant="text">
          Go to storyboard
          <ChevronRightIcon fontSize="small" />
        </ActionButton>
        {customer && customer.audioIssueEnabled && issue.audioUrl ? (
          <ActionButton
            onClick={() => {
              scheduleAudioGenerationMutation({ id: issue.id });
              enqueueSnackbar("Scheduled audio regeneration for the issue", {
                variant: "info",
              });
            }}
            variant="text"
          >
            Re-Generate audio file
          </ActionButton>
        ) : null}
      </FullWidthCardActions>
      <ConfirmDialog
        open={confirmOpen}
        onConfirmAction={() => {
          setConfirmOpen(false);
          createNotification();
        }}
        onDeclineAction={() => {
          setConfirmOpen(false);
        }}
        dialogOptions={{
          title: "Send notification mail",
          prompt: (
            <DialogContentText>
              Send notification mail to the client?
            </DialogContentText>
          ),
          confirmText: "Proceed",
          declineText: "Cancel",
        }}
      />
    </IssueCard>
  );
}
