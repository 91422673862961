import {
  Card,
  CardHeader,
  styled,
  Avatar,
  ListItemText,
  CardActions,
  Chip,
  ButtonGroup,
  Button,
  TextField,
} from "@mui/material";
import { Public as PublicIcon, PublicOff } from "@mui/icons-material";
import { Caption, TextLarge, Text } from "@tbml/components/Typography";
import tokens from "@tbml/tokens";
import { IssuePublicationStatus } from "@tbml/api-interface/graphql";
import { Issue } from "@tbml/hooks/useIssues";

export const STORY_PREVIEW_WIDTH = 90;
export const STORY_PREVIEW_HEIGHT = 30;

export const SectionAccordionHeader = styled(TextLarge)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const FullWidthCardActions = styled(CardActions)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing.verticalXs.value};
  padding: ${tokens.spacing.gutterS.value};
`;

export const PrimaryActionButtonGroup = styled(ButtonGroup)`
  width: 100%;
  > button {
    flex: 1;
  }
`;

export const ActionButton = styled(Button)`
  padding: 9px 8px;
`;

export const CollapsableContent = styled("div")`
  padding: 0 ${tokens.spacing.gutterM.value};
`;

export const TopicTitle = styled(Caption)`
  display: flex;
  align-items: center;
`;

const getBorderTopColor = ({
  issue,
  isDefault,
}: {
  issue: Issue;
  isDefault: boolean;
}) => {
  switch (issue.publicationStatus) {
    case IssuePublicationStatus.Published:
      return isDefault
        ? tokens.color.successMain.value
        : tokens.color.brandMain.value;
    default:
      return tokens.color.textDisabled.value;
  }
};

export const IssueCard = styled(Card)`
  border-radius: ${({ theme }) => theme?.shape.borderRadius}px;
  box-shadow: ${({ theme }) => {
    if (!theme || !theme.transitions) return false;
    const {
      shadows: [, , , fourthShadow],
    } = theme;
    return fourthShadow;
  }};
`;

export const IssueOverviewHeader = styled(CardHeader, {
  shouldForwardProp: (propName) =>
    propName !== "issue" && propName !== "isDefault",
})<{
  issue: Issue;
  isDefault: boolean;
}>`
  border-top: ${({ isDefault, issue }) =>
    `4px solid ${getBorderTopColor({ issue, isDefault })}`};
`;

export const WhiteAvatar = styled(Avatar)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: ${tokens.color.backgroundDefault.value};
`;
export const PublishedIcon = styled(PublicIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const UnpublishedIcon = styled(PublicOff)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DottedListItemText = styled(ListItemText)`
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: ${tokens.color.textSecondary.value};
  cursor: pointer;
`;

export const TitleText = styled(Text)`
  display: inline-block;
  cursor: pointer;
`;

export const DefaultChip = styled(Chip)`
  color: ${tokens.color.backgroundPaper.value};
`;

export const VerticallyCentered = styled("div")`
  display: flex;
  align-items: center;
`;

export const ImagePlaceholder = styled("div")`
  background: ${tokens.color.textDisabled.value};
  width: ${STORY_PREVIEW_WIDTH}px;
  height: ${STORY_PREVIEW_HEIGHT}px;
`;

export const FullWidthTextField = styled(TextField)`
  width: 100%;
`;

export const SocialMediaSelector = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${tokens.color.backgroundPaper.value};
  padding: ${tokens.spacing.paddingS.value};
  padding-top: ${tokens.spacing.paddingS.original.value + 6}px;
  transition: background 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    background: ${tokens.color.backgroundDefaultArticles.value};
  }
`;
