import * as React from "react";
import { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
  TablePagination,
} from "@mui/material";
import { gql } from "graphql-request";
import { MutationStatus } from "@tanstack/react-query";
import { useRouter } from "@tbml/shared-dependencies/router";
import { useIssues } from "@tbml/hooks/useIssues";
import { H1, Text, TextSmall } from "@tbml/components/Typography";
import { Spacer } from "@tbml/components/Spacer";
import { CurrentCustomerIdProvider } from "@tbml/hooks/useCurrentCustomerId";
import { useSnackbar } from "@tbml/shared-dependencies/notistack";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { BrandColorFetcher, TbmlTheme } from "@tbml/components/Theme";
import { useStatus } from "../AppBar";
import { IssueOverviewItem } from "../IssueOverviewItem";
import { Headline, VerticallyCentered } from "./styles";
import { IssueCreationButton } from "./IssueCreationButton";

type Props = {
  customerId: string;
};

const issuesPageCustomerFragmentName = "IssuesPageCustomerFields";
const issuesPageCustomerFragment = gql`
  fragment IssuesPageCustomerFields on Customer {
    defaultIssueId
    name
  }
`;
export const issuesPageIssueFragmentName = "IssuesPageFields";
export const issuesPageIssueFragment = gql`
  fragment IssuesPageFields on Issue {
    id
    title
    audioUrl
    inboxProductIssueId
    publicationStatus
    refDate
    sectionOrder
    additionalCoverage {
      productId
    }
    notifications {
      scheduledAt
    }
    executiveBriefingTopics {
      id
      title
      subTitle
      stories {
        id
        title
        image {
          id
          originPath
          mediaWidth
          mediaHeight
          allowCropping
          showOriginalSize
        }
        broadcastData {
          id
        }
        editorial
        executiveBriefingTopicId
      }
    }
    errors
  }
`;

export function Issues({ customerId, ...props }: Props): JSX.Element {
  const { push, query, pathname } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { setStatus } = useStatus({ persistAfterUnmount: true });
  const {
    query: queryIssues,
    mutator: { status: mutationStatus, error: mutationError },
    deleter: { status: deletionStatus, error: deletionError },
  } = useIssues();
  const { data, status, error } = queryIssues({
    filter: { customerId, skip: page * rowsPerPage, take: rowsPerPage },
    fragmentName: issuesPageIssueFragmentName,
    fragment: issuesPageIssueFragment,
  });

  const { query: queryCustomers } = useCustomers();
  const { data: customers } = queryCustomers({
    filter: { ids: [customerId] },
    fragmentName: issuesPageCustomerFragmentName,
    fragment: issuesPageCustomerFragment,
  });

  const defaultIssueId = customers?.[0]?.defaultIssueId;

  const issues = React.useMemo(() => data?.issues, [data]);

  const setLoadingState = React.useCallback(
    (reactQueryStatus: MutationStatus) => {
      switch (reactQueryStatus) {
        case "pending":
          setStatus("saving");
          break;
        case "success":
          setStatus("saved");
          setTimeout(() => {
            setStatus("idle");
          }, 5000);
          break;
        case "idle":
        case "error":
        default:
          setStatus("idle");
      }
    },
    [setStatus]
  );

  useEffect(
    () => setLoadingState(deletionStatus),
    [deletionStatus, setLoadingState]
  );

  useEffect(
    () => setLoadingState(mutationStatus),
    [mutationStatus, setLoadingState]
  );

  useEffect(() => {
    if (typeof query.perPage !== "string") return;
    const queryPerPage = parseInt(query.perPage, 10);
    if (queryPerPage !== rowsPerPage) setRowsPerPage(queryPerPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (typeof query.page !== "string") return;
    const queryPage = parseInt(query.page, 10);
    if (queryPage !== page) setPage(queryPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (mutationStatus === "error") {
      enqueueSnackbar(`Could not modify issue: ${mutationError?.message}`, {
        variant: "error",
      });
    }
  }, [mutationStatus, mutationError, enqueueSnackbar]);

  useEffect(() => {
    if (deletionStatus === "error") {
      enqueueSnackbar(`Could not delete issue: ${deletionError?.message}`, {
        variant: "error",
      });
    }
  }, [deletionStatus, deletionError, enqueueSnackbar]);

  if (status === "error") {
    return (
      <Alert severity="error">
        <AlertTitle>Error getting issues:</AlertTitle>
        <TextSmall>{error?.message}</TextSmall>
      </Alert>
    );
  }

  if (status === "pending") {
    return (
      <VerticallyCentered {...props}>
        <CircularProgress />
        <Spacer />
        Loading issues...
      </VerticallyCentered>
    );
  }

  if (!issues) {
    throw new Error("Unable to access issues");
  }

  return (
    <CurrentCustomerIdProvider value={customerId}>
      <BrandColorFetcher>
        <TbmlTheme>
          <div {...props}>
            <Headline>
              <H1>Issues</H1>
              <IssueCreationButton
                onIssueCreated={() => {
                  push({
                    pathname,
                    query: {
                      ...query,
                      page: "0",
                    },
                  });
                  setPage(0);
                }}
              />
            </Headline>
            <Spacer size="verticalXxl" />
            {issues.length === 0 ? (
              <Text>No issues exist for this customer.</Text>
            ) : (
              <>
                <Grid container spacing={2}>
                  {issues.map((issue) => (
                    <Grid item sm={12} md={6} lg={4} key={issue.id}>
                      <IssueOverviewItem
                        aria-label={`Issue ${issue.title}`}
                        issue={issue}
                        customerId={customerId}
                        isDefault={defaultIssueId === issue.id}
                      />
                    </Grid>
                  ))}
                </Grid>
                <TablePagination
                  aria-label="Issue pagination"
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data?.count ?? 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, newPage) => {
                    push({ pathname, query: { ...query, page: `${newPage}` } });
                    setPage(newPage);
                  }}
                  onRowsPerPageChange={(event) => {
                    push({
                      pathname,
                      query: {
                        ...query,
                        page: "0",
                        perPage: event.target.value,
                      },
                    });
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </>
            )}
          </div>
        </TbmlTheme>
      </BrandColorFetcher>
    </CurrentCustomerIdProvider>
  );
}
